import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { Table, TableHead, TableBody, TableRow, TableHeader, TableCell } from 'carbon-components-react';
import { DocumentBlank } from '@carbon/icons-react';
import { useDispatch } from 'react-redux';
import useToastAlert from '../../../../hooks/useToastAlert'
import { __trans, getData } from '../../../../_helpers';

const UploadDocument = ({ request, show }) => {
	const { addError } = useToastAlert();
	const dispatch = useDispatch();
	const [docData, setDocData]         = useState({});
    const [uploadedDoc, setUploadedDoc] = useState({});
	
	const getDocumentList = async () => {
		await getData("template/get-client-template-document/" + request._id, {}, 'POST', addError, dispatch)
			.then((res) => {
				//console.log('res', res)
				if (res.type === "success") {
					setDocData(res.documents[0].documents)
					setUploadedDoc(res.uploaded)
				}
			});
	}

	useEffect(() => {
		if(show === 3){
			getDocumentList();
		}
	}, [show])
	
    return (
        <>
			{/* Step 3 START  */}
			<div className='container mb-5 border p-3'>
			<div className='row mb-4'>
				<div className='col cstm-top-heading pt-4'>
					<h6>{__trans('account_setup_step3_info')}</h6>
				</div>
				{request.documents_template_id !== null ?
					<div className='col d-flex justify-content-end'>
						<div className='requestedFilesCompleted p-4 d-flex align-items-center gap-2'>
							<DocumentBlank width={'25px'} height={'25px'} color='#707BBD' />
							<h3>{uploadedDoc.length}/{docData.length}</h3>
							<p>{__trans('requested_files_completed')}</p>
						</div>
					</div>
				:''
				}				
			</div>
			<div className='row'>
				<div className="col">
					<div className='row'>
					{request.documents_template_id !== null ?
						<>
						{/* Client Requested Document*/}
						<div className='col-md-12 mb-4'>
							<h5 className="mb-2">{__trans('client_setup_doc_list_title')}</h5>
							<Table aria-label="sample table" size="sm">
							  <TableHead>
								<TableRow>
								  <TableHeader>
								  
								  </TableHeader>
								  <TableHeader>
									{__trans('client_setup_doc_table_header.file_name')}
								  </TableHeader>
								  <TableHeader>
									{__trans('client_setup_doc_table_header.description')}
								  </TableHeader>
								  <TableHeader>
									{__trans('client_setup_doc_table_header.created_at')}
								  </TableHeader>
								</TableRow>
							  </TableHead>
							  <TableBody>
								{docData.length && docData.map((item, index) => (
									<TableRow key={item._id}>
									  <TableCell>
										{index + 1}
									  </TableCell>
									  <TableCell>
										{item.name}
									  </TableCell>
									  <TableCell>
										{item.description}
									  </TableCell>
									  <TableCell>
										{moment(item.updated_at).format('MM/DD/YYYY')}
									  </TableCell>
									</TableRow>
								))}
							  </TableBody>
							</Table> 
						</div>
						{/*Client Uploaded Document*/}
						{uploadedDoc.length > 0 ?
							<div className='col-md-12 '>
								<h5 className="mb-2">{__trans('client_setup_uploadeddoc_list_title')}</h5>
								<Table aria-label="sample table" size="sm">
								  <TableHead>
									<TableRow>
									  <TableHeader>
										{__trans('client_setup_uploadeddoc_table_header.file_name')}
									  </TableHeader>
									  <TableHeader>
										{__trans('client_setup_uploadeddoc_table_header.description')}
									  </TableHeader>
									  <TableHeader>
										{__trans('client_setup_uploadeddoc_table_header.created_at')}
									  </TableHeader>
									</TableRow>
								  </TableHead>
								  <TableBody>
									{uploadedDoc.length && uploadedDoc.map((item, index) => (
										<TableRow key={item._id}>
										  <TableCell>
											{item.original_file_name}
										  </TableCell>
										  <TableCell>
											{item.description}
										  </TableCell>
										  <TableCell>
										  {moment(item.created_at).format('MM/DD/YYYY')}
										  </TableCell>
										</TableRow>
									))}
								  </TableBody>
								</Table> 
							</div>
						:''
						}
						</>
						:
						<div className='text-center my-5'>
							<h5>{__trans('no_document_asked')}</h5>
						</div>
						}
					</div>
				</div>
				
			</div>
			</div>
        </>
    );
};

export default UploadDocument;